// @ts-strict-ignore
import { SubPageLayout } from '@/layouts';

export const ProjectImprovementsSubPageLayout = () => {
    return (
        <SubPageLayout
            backLinkProps={{
                to: '../improve',
                children: 'Tillbaka till översikt förbättringsarbete',
            }}
        />
    );
};
