/**
 * @generated SignedSource<<43298edcba26a79bbfb70c2d965a3385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamMemberRoleName = "PROJECT_CREATOR" | "PROJECT_MANAGER" | "RESULTS_VIEWER" | "SURVEY_ACTIVATOR" | "SURVEY_ADMIN" | "SURVEY_ADMINISTRATOR" | "SURVEY_PLANNER" | "TEAM_ADMIN" | "TEAM_OWNER" | "%future added value";
export type GrantTeamMemberAccessInput = {
  email: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  name?: string | null | undefined;
  roles: ReadonlyArray<TeamMemberRoleInput>;
  teamId: string;
};
export type TeamMemberRoleInput = {
  name: TeamMemberRoleName;
  portfolioId?: string | null | undefined;
  projectId?: string | null | undefined;
};
export type RevokeTeamMemberAccessInput = {
  roles: ReadonlyArray<TeamMemberRoleInput>;
  teamId: string;
  userId: string;
};
export type EditTeamMemberMutation$variables = {
  grant: GrantTeamMemberAccessInput;
  revoke: RevokeTeamMemberAccessInput;
};
export type EditTeamMemberMutation$data = {
  readonly grantTeamMemberAccess: {
    readonly team: {
      readonly members: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"EditTeamMemberForm_member">;
      }>;
    } | null | undefined;
  };
  readonly revokeTeamMemberAccess: {
    readonly team: {
      readonly members: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"EditTeamMemberForm_member">;
      }>;
    } | null | undefined;
  };
};
export type EditTeamMemberMutation = {
  response: EditTeamMemberMutation$data;
  variables: EditTeamMemberMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "grant"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "revoke"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "grant"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Team",
    "kind": "LinkedField",
    "name": "team",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditTeamMemberForm_member"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "revoke"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "portfolioId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Team",
    "kind": "LinkedField",
    "name": "team",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamMemberNotification",
            "kind": "LinkedField",
            "name": "enabledNotifications",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "roles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/)
                ],
                "type": "TeamMemberPortfolioRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectId",
                    "storageKey": null
                  }
                ],
                "type": "TeamMemberProjectRole",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditTeamMemberMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GrantTeamMemberAccessPayload",
        "kind": "LinkedField",
        "name": "grantTeamMemberAccess",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "RevokeTeamMemberAccessPayload",
        "kind": "LinkedField",
        "name": "revokeTeamMemberAccess",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditTeamMemberMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GrantTeamMemberAccessPayload",
        "kind": "LinkedField",
        "name": "grantTeamMemberAccess",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "RevokeTeamMemberAccessPayload",
        "kind": "LinkedField",
        "name": "revokeTeamMemberAccess",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f44d1f4df38f0841b61ccba3079e9240",
    "id": null,
    "metadata": {},
    "name": "EditTeamMemberMutation",
    "operationKind": "mutation",
    "text": "mutation EditTeamMemberMutation(\n  $grant: GrantTeamMemberAccessInput!\n  $revoke: RevokeTeamMemberAccessInput!\n) {\n  grantTeamMemberAccess(input: $grant) {\n    team {\n      members {\n        ...EditTeamMemberForm_member\n        id\n      }\n      id\n    }\n  }\n  revokeTeamMemberAccess(input: $revoke) {\n    team {\n      members {\n        ...EditTeamMemberForm_member\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment EditTeamMemberForm_member on TeamMember {\n  ...TeamMemberNotifications_member\n  user {\n    id\n    fullName\n    emailAddress\n  }\n  roles {\n    __typename\n    name\n    ... on TeamMemberProjectRole {\n      projectId\n    }\n    ... on TeamMemberPortfolioRole {\n      portfolioId\n    }\n  }\n}\n\nfragment TeamMemberNotifications_member on TeamMember {\n  user {\n    id\n  }\n  enabledNotifications {\n    type\n    portfolioId\n  }\n  roles {\n    __typename\n    name\n    ... on TeamMemberPortfolioRole {\n      portfolioId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c560e3fd17d3d59e40a49592cd6f923d";

export default node;
