/**
 * @generated SignedSource<<b13624876a5af239dfde5d5e41de40d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioBreadcrumbs_project$data = {
  readonly name: string;
  readonly portfolio: {
    readonly id: string;
  };
  readonly " $fragmentType": "PortfolioBreadcrumbs_project";
};
export type PortfolioBreadcrumbs_project$key = {
  readonly " $data"?: PortfolioBreadcrumbs_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioBreadcrumbs_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioBreadcrumbs_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "portfolio",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInterface",
  "abstractKey": "__isProjectInterface"
};

(node as any).hash = "1e9a93eacf6f7972110449252d5839bf";

export default node;
