import { LayoutDataContext } from './LayoutDataContext';

const { Provider } = LayoutDataContext;
export const LayoutDataProvider = ({
    team = null,
    user = null,
    requireAuthenticatedUser = true,
    children,
}: any) => (
    <Provider
        value={{
            team,
            user,
            requireAuthenticatedUser,
        }}
    >
        {children}
    </Provider>
);
