import type { FunctionComponent, ReactNode } from 'react';
import { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import type { FormControlProps } from '@mui/material';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { tss } from 'tss-react/mui';
import type { PortfolioListItem } from '../../utils/usePortfolioList';
import { usePortfolioList } from '../../utils/usePortfolioList';
import uniqueId from '@/services/uniqueId';
import type { PortfolioSelect_team$key } from './__generated__/PortfolioSelect_team.graphql';

const usePortfolioSelectStyles = tss.create(({ theme }) => ({
    select: {
        // We want the deepest sub-portfolio to be visible in case of overflow. This makes the
        // ellipsis overflow appear on the opposite side (before rather than after)
        //        direction: 'rtl',
        textAlign: 'left',
        '& > span:not(:last-of-type)': {
            fontSize: 12,
            color: theme.bino.color.gray,
        },
        '& > span:last-of-type': {
            display: 'block',
        },
        whiteSpace: 'normal!important',
    },
}));

export interface PortfolioSelectProps
    extends Omit<FormControlProps, 'onChange'> {
    team: PortfolioSelect_team$key;
    label?: ReactNode;
    // For an empty-state, use an empty string
    portfolioId: string;
    onlySubPortfolios?: boolean;

    onChange(portfolioId: string): void;

    filter?(portfolio: PortfolioListItem): boolean;
}

export const PortfolioSelect: FunctionComponent<PortfolioSelectProps> = (
    props,
) => {
    const {
        filter,
        portfolioId,
        label,
        onChange,
        onlySubPortfolios,
        team: team$ref,
        ...formControlProps
    } = props;
    const team = useFragment(teamFragment, team$ref);
    const { classes } = usePortfolioSelectStyles(props);
    const portfolioList = usePortfolioList(team, null).filter(
        filter ?? (() => true),
    );
    const [selectId] = useState<string>(() => `portfolio-select-${uniqueId()}`);
    const labelId = `${selectId}-label`;

    const renderSelectValue = (value: any) => {
        if (value === team.mainPortfolio.id) {
            return (
                <span>
                    <span>{team.name}</span>
                    <Typography
                        component="span"
                        variant="label"
                        textTransform="uppercase"
                        ml={2}
                    >
                        <FormattedMessage defaultMessage="Main portfolio" />
                    </Typography>
                </span>
            );
        }
        const item = portfolioList.find(
            ({ portfolio }) => portfolio.id === portfolioId,
        );
        const parentsReversed = (item?.getAncestors() || []).slice().reverse();
        return (
            <>
                {!onlySubPortfolios && <span>{team.name + ' / '}</span>}
                {parentsReversed.length > 0 && (
                    <span>
                        {parentsReversed.map(({ name }) => name).join(' / ') +
                            ' / '}
                    </span>
                )}
                <span>{item?.portfolio.name}</span>
            </>
        );
    };

    return (
        <FormControl margin="normal" {...formControlProps}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                label={
                    label /*Seems we need both label and InputLabel to make it work */
                }
                id={selectId}
                value={portfolioId}
                classes={{ select: classes.select }}
                renderValue={renderSelectValue}
                onChange={(event) => onChange(event.target.value as string)}
            >
                {!onlySubPortfolios && (
                    <MenuItem value={team.mainPortfolio.id} divider>
                        {team.name}
                    </MenuItem>
                )}
                {portfolioList.map(({ portfolio, getAncestors }) => (
                    <MenuItem key={portfolio.id} value={portfolio.id}>
                        <span
                            style={{
                                paddingLeft: 1 + getAncestors().length + 'em',
                            }}
                        >
                            {portfolio.name}
                        </span>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const teamFragment = graphql`
    fragment PortfolioSelect_team on Team {
        ...usePortfolioList_team
        name
        mainPortfolio {
            id
        }
    }
`;
