/**
 * @generated SignedSource<<987ceffe29aa7d9a76ef31f55ec7f021>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsAdmin_user$data = {
  readonly emailAddress: string;
  readonly " $fragmentType": "useIsAdmin_user";
};
export type useIsAdmin_user$key = {
  readonly " $data"?: useIsAdmin_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsAdmin_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsAdmin_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "558ba39812fd5758b448017ceca0b3e1";

export default node;
