import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Navigate, useParams, useMatch } from 'react-router';
import { defineMessages, useIntl } from 'react-intl';
import { cpsPageUrlAnchor } from '@/pages/CPS';
import type { RouteParams } from '@/RootPaths';
import { getPortfolioFullPath } from '@/RootPaths';
import { Beta, useBeta } from '@/utils/useBeta';
import {
    DashboardIcon,
    HelpIcon,
    ImprovementIcon,
    PlusIcon,
    ProjectsIcon,
    SettingsIcon,
    SurveysIcon,
} from '@/components/icons';
import type { MainNavigationProps } from '@/components/MainNavigation';
import { MainNavigation } from '@/components/MainNavigation';
import type { MainMenu_pqiUser$key } from './__generated__/MainMenu_pqiUser.graphql';
import type { MainMenu_team$key } from './__generated__/MainMenu_team.graphql';

const messages = defineMessages({
    dashBoard: {
        defaultMessage: 'Dashboard',
        description: 'Menu item label',
    },
    projects: {
        defaultMessage: 'Projects',
        description: 'Menu item label',
    },
    compare: {
        defaultMessage: 'Compare',
        description: 'Menu item label',
    },
    improve: {
        defaultMessage: 'Improve',
        description: 'Menu item label',
    },
    reports: {
        defaultMessage: 'Reports',
        description: 'Menu item label',
    },
    settings: {
        defaultMessage: 'Team & Tools',
        description: 'Menu item label',
    },
    profile: {
        defaultMessage: 'Profile',
        description: 'Menu item label',
    },
    helpCenter: {
        defaultMessage: 'Help center',
        description: 'Menu item label',
    },
    calendar: {
        defaultMessage: 'Calendar',
        description: 'Menu item label',
    },
    createNew: {
        defaultMessage: 'Create project',
        description: 'Menu item label',
    },
});

export interface MainMenuProps {
    dashboard?: boolean;
    mobile?: boolean;
    pqiUser: MainMenu_pqiUser$key | null;
    team: MainMenu_team$key | null;
}

const fragments = {
    pqiUser: graphql`
        fragment MainMenu_pqiUser on PQiUser {
            grants {
                teamId
                portfolioId
                projectId
                role
            }
        }
    `,
    team: graphql`
        fragment MainMenu_team on Team {
            id
        }
    `,
};
export const MainMenu: FC<MainMenuProps> = (props) => {
    const { dashboard = false, mobile = false } = props;
    const { formatMessage } = useIntl();
    const params = useParams<RouteParams>();
    const [beta] = useBeta();
    const pqiUser = useFragment(fragments.pqiUser, props.pqiUser);
    const team = useFragment(fragments.team, props.team);

    const teamId = team?.id;

    const grants = (pqiUser?.grants || []).filter(
        (grant) => grant.teamId === teamId || !grant.teamId,
    );
    const isTeamOwner = grants.some((grant) => grant.role === 'TEAM_OWNER');
    const isTeamAdmin = grants.some((grant) => grant.role === 'TEAM_ADMIN');
    const canViewResults =
        isTeamOwner ||
        isTeamAdmin ||
        grants.some(
            (grant) =>
                grant.role === 'SURVEY_ADMIN' ||
                grant.role === 'RESULTS_VIEWER',
        );
    const canCreateProject =
        isTeamOwner ||
        isTeamAdmin ||
        grants.some(
            (grant) =>
                !grant.projectId &&
                (grant.role === 'PROJECT_CREATOR' ||
                    grant.role === 'SURVEY_ADMIN'),
        );

    const showSettingsRoute = isTeamOwner || isTeamAdmin;

    const items = [] as Array<MainNavigationProps['items'][number]>;
    const portfolioPath = getPortfolioFullPath(params);

    if (params.teamPath) {
        if (canViewResults) {
            items.push({
                to: `/${portfolioPath}/dashboard`,
                label: formatMessage(messages.dashBoard),
                iconComponent: DashboardIcon,
            });
        }
        items.push({
            to: `/${portfolioPath}/projects`,
            label: formatMessage(messages.projects),
            iconComponent: ProjectsIcon,
        });
        if (canViewResults) {
            items.push({
                to: `/${portfolioPath}/reports`,
                label: formatMessage(messages.reports),
                iconComponent: SurveysIcon,
                modalPriority: 1,
            });
        }

        if (beta) {
            items.push({
                to: `/${portfolioPath}/improvement-bank`,
                label: <Beta>{formatMessage(messages.improve)}</Beta>,
                iconComponent: ImprovementIcon,
            });
        }

        // items.push({to: `/${portfolioPath}/compare`, label: messages.compare, iconComponent: CompareIcon});
        // items.push({
        //     to: `/${portfolioPath}/calendar`,
        //     label: messages.calendar,
        //     iconComponent: CalendarIcon,
        //     modalOnly: true,
        // });

        if (showSettingsRoute) {
            items.push({
                to: `/${portfolioPath}/settings`,
                label: formatMessage(messages.settings),
                iconComponent: SettingsIcon,
                modalPriority: 2,
            });
        }

        items.push({
            to: `/${portfolioPath}/help-center`,
            label: formatMessage(messages.helpCenter),
            iconComponent: HelpIcon,
        });

        if (canCreateProject) {
            items.push({
                to: `/${portfolioPath}/${cpsPageUrlAnchor}`,
                label: formatMessage(messages.createNew),
                iconComponent: PlusIcon,
                cta: true,
                iconOnlyOnMobile: true,
                modalPriority: -1,
            });
        }
    } else {
        items.push({
            to: `/${portfolioPath}/help-center`,
            label: formatMessage(messages.helpCenter),
            iconComponent: HelpIcon,
        });
    }

    // We can protect these routes when we use react-router v6 data-router. This here is a strange place to do this
    const isDashboardRoute = null !== useMatch('/:teamPath/dashboard/*');
    const isResultsRoute = null !== useMatch('/:teamPath/results/*');
    if (
        !canViewResults &&
        params.teamPath &&
        (isDashboardRoute || isResultsRoute)
    ) {
        return <Navigate to={`/${params.teamPath}/projects`} />;
    }

    return (
        <MainNavigation mobile={mobile} items={items} dashboard={dashboard} />
    );
};
