/**
 * @generated SignedSource<<944e109542fa21ac90c69b7861d5ed9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainMenu_team$data = {
  readonly id: string;
  readonly " $fragmentType": "MainMenu_team";
};
export type MainMenu_team$key = {
  readonly " $data"?: MainMenu_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainMenu_team">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainMenu_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};

(node as any).hash = "a822ffe06627ee5f8d99324970dea504";

export default node;
