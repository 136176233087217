/**
 * @generated SignedSource<<77a52bc9fe25635e2d425d51a61b9275>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoleSelectWithPortfolioOrProject_team$data = {
  readonly mainPortfolio: {
    readonly id: string;
  };
  readonly portfolio: {
    readonly projects: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioSelect_team">;
  readonly " $fragmentType": "RoleSelectWithPortfolioOrProject_team";
};
export type RoleSelectWithPortfolioOrProject_team$key = {
  readonly " $data"?: RoleSelectWithPortfolioOrProject_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoleSelectWithPortfolioOrProject_team">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleSelectWithPortfolioOrProject_team",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioSelect_team"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MainPortfolio",
      "kind": "LinkedField",
      "name": "mainPortfolio",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "portfolio",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "735a30e2e72e20ca6c6584019004b3b4";

export default node;
