/**
 * @generated SignedSource<<c1f29418c4e00f33c5742b33e041371b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioBreadcrumbs_pqiUser$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usePortfolioList_pqiUser">;
  readonly " $fragmentType": "PortfolioBreadcrumbs_pqiUser";
};
export type PortfolioBreadcrumbs_pqiUser$key = {
  readonly " $data"?: PortfolioBreadcrumbs_pqiUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioBreadcrumbs_pqiUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioBreadcrumbs_pqiUser",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePortfolioList_pqiUser"
    }
  ],
  "type": "PQiUser",
  "abstractKey": null
};

(node as any).hash = "6fe0bce9f8bcd6e076d8b10139b781f5";

export default node;
