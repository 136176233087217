import { Suspense, useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { graphql, usePreloadedQuery, useQueryLoader } from 'react-relay';
import type { PreloadedQuery } from 'react-relay';
import { Link, useParams } from 'react-router';
import { Button, styled, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator, Paragraph, H4 } from '@/components';
import { useSiteLocale } from './hooks/useSiteLocale';
import type {
    HelpCenterRelatedArticlesSidebarQuery,
    HelpCenterRelatedArticlesSidebarQuery$data,
} from './__generated__/HelpCenterRelatedArticlesSidebarQuery.graphql';

const ArticleTitle = styled('h5')(({ theme }) => ({
    padding: 0,
    margin: theme.spacing(2, 0, 0.5),
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '20px',
    letterSpacing: 1,
    textTransform: 'uppercase',
}));
const ReadMoreLink = styled(Link)(({ theme }) => ({
    display: 'block',
    textDecoration: 'underline',
    margin: theme.spacing(1, 0, 4),
}));
export interface HelpCenterRelatedArticlesSidebarProps {
    pageId: string;
}

type Article =
    HelpCenterRelatedArticlesSidebarQuery$data['list']['relatedArticles'][number];

export const HelpCenterRelatedArticlesSidebar: FunctionComponent<
    HelpCenterRelatedArticlesSidebarProps
> = ({ pageId }) => {
    const [queryRef, loadQuery] =
        useQueryLoader<HelpCenterRelatedArticlesSidebarQuery>(query);
    const locale = useSiteLocale();

    useEffect(() => {
        loadQuery({
            pageId,
            locale,
        });
    }, [pageId, locale, loadQuery]);

    return (
        <Suspense fallback={<LoadingIndicator />}>
            {queryRef && (
                <HelpCenterRelatedArticlesSidebarContent query={queryRef} />
            )}
        </Suspense>
    );
};

const HelpCenterRelatedArticlesSidebarContent = (props: {
    query: PreloadedQuery<HelpCenterRelatedArticlesSidebarQuery>;
}) => {
    const data = usePreloadedQuery(query, props.query);
    const params = useParams<{ teamPath?: string }>();

    // In case we are viewing a team page (very likely) then keep that team path in the
    // url. In case the user opens the help center in a new browser tab, this will still
    // work since we use a cookie for auth
    const helpCenterUrl = params.teamPath
        ? `/${params.teamPath}/help-center`
        : '/help-center';

    const articleUrl = (article: Article): string =>
        `${helpCenterUrl}/${article.topic.url}#${article.url}`;

    const relatedArticles = data.list?.relatedArticles || [];

    if (!data) {
        return null;
    }

    return (
        <>
            <H4>
                <FormattedMessage defaultMessage="Related articles" />
            </H4>
            {relatedArticles.length === 0 && (
                <Paragraph small>
                    <FormattedMessage defaultMessage="No articles found" />
                </Paragraph>
            )}
            {relatedArticles.map((article) => (
                <div key={article.id}>
                    <ArticleTitle>{article.title}</ArticleTitle>
                    <Paragraph small>{article.summary}</Paragraph>
                    <Paragraph small>
                        <ReadMoreLink
                            to={articleUrl(article)}
                            target={'binosight-pqi-help-center'}
                        >
                            <FormattedMessage defaultMessage="Read more" />
                        </ReadMoreLink>
                    </Paragraph>
                </div>
            ))}
            <Box
                sx={(theme) => ({
                    position: 'sticky',
                    bottom: 0,
                    pt: 2,
                    pb: 1,
                    background: theme.palette.background.paper,
                })}
            >
                <Box
                    component="p"
                    sx={(theme) => ({
                        fontWeight: 'bold',
                        fontSize: 12,
                        lineHeight: '16px',
                        textTransform: 'uppercase',
                        letterSpacing: 0.55,
                        color:
                            theme.palette.mode === 'light'
                                ? theme.bino.color.darkGray
                                : theme.bino.color.lightGray,
                    })}
                >
                    <FormattedMessage defaultMessage="Have another question?" />
                </Box>
                <Button
                    variant="outlined"
                    component={Link}
                    fullWidth
                    to={helpCenterUrl}
                    target={'binosight-pqi-help-center'}
                >
                    <FormattedMessage defaultMessage="Open help center" />
                </Button>
            </Box>
        </>
    );
};

const query = graphql`
    query HelpCenterRelatedArticlesSidebarQuery(
        $locale: SiteLocale!
        $pageId: String!
    ) {
        list: helpCenterRelatedArticlesList(
            locale: $locale
            filter: { pageId: { eq: $pageId } }
        ) {
            relatedArticles {
                id
                title
                summary
                url
                topic {
                    url
                }
            }
        }
    }
`;
