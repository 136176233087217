import type { MessageDescriptor } from 'react-intl';
import { TeamMemberRoleName as RoleName } from '@/schema.graphql';
import { teamRolesMessages } from '../../rolesMessages';
import type { Role } from './RoleSelect';

export type RoleSelectResourceType = 'team' | 'portfolio';
export type RoleSelectOption = {
    value: RoleName;
    resourceTypes: ReadonlyArray<RoleSelectResourceType>;
    name: MessageDescriptor;
    description: MessageDescriptor;
    deprecated?: boolean;
};
export const findRoleSelectOption = (role?: Role): RoleSelectOption | null => {
    if (!role) {
        return null;
    }
    if (role.projectId) {
        throw new Error('Cannot edit this role here');
    }
    const option = roleSelectOptions.find((o) => o.value === role.name);
    if (option) {
        return option;
    }
    throw new Error('Role select option not found');
};

export const roleSelectOptions: ReadonlyArray<RoleSelectOption> = [
    {
        value: RoleName.TeamOwner,
        resourceTypes: ['team'],
        deprecated: true,
        ...teamRolesMessages[RoleName.TeamOwner],
    },
    {
        value: RoleName.TeamAdmin,
        resourceTypes: ['team'],
        ...teamRolesMessages[RoleName.TeamAdmin],
    },
    {
        // NOTE The same role: "SurveyAdmin" is used also for administators in projects, but we do not handle project grants here
        value: RoleName.SurveyAdmin,
        resourceTypes: ['team', 'portfolio'],
        ...teamRolesMessages[RoleName.SurveyAdmin],
    },
    {
        // can create projects in the selected team/portfolio, and becomes a project administrator in the projects that they create
        value: RoleName.ProjectCreator,
        resourceTypes: ['team', 'portfolio'],
        ...teamRolesMessages[RoleName.ProjectCreator],
    },
    {
        value: RoleName.ResultsViewer,
        resourceTypes: ['team', 'portfolio'],
        ...teamRolesMessages[RoleName.ResultsViewer],
    },
];
