// @ts-strict-ignore
import { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import naturalCompare from 'natural-compare-lite';
import getMaxDescendantsLevel from './getMaxDescendantsLevel';
import type {
    usePortfolioList_team$key,
    usePortfolioList_team$data,
} from './__generated__/usePortfolioList_team.graphql';
import type {
    usePortfolioList_pqiUser$key,
    usePortfolioList_pqiUser$data,
} from './__generated__/usePortfolioList_pqiUser.graphql';

type PQiUserGrant = usePortfolioList_pqiUser$data['grants'][0];

export type MainPortfolio = usePortfolioList_team$data['mainPortfolio'];
export type SubPortfolio = MainPortfolio['subPortfolios'][number] & {
    subPortfolios: ReadonlyArray<SubPortfolio>;
    grantedRoles: ReadonlyArray<PQiUserGrant['role']>;
};

type IsGrantedMatcher = (grant: PQiUserGrant) => boolean;
export type IsGrantedFunction = (matcher: IsGrantedMatcher) => boolean;

const fragments = {
    team: graphql`
        fragment usePortfolioList_team on Team {
            id
            path
            name
            mainPortfolio {
                id
                subPortfolios {
                    id
                    path
                    name
                    parent {
                        id
                    }
                }
            }
        }
    `,
    pqiUser: graphql`
        fragment usePortfolioList_pqiUser on PQiUser {
            grants {
                teamId
                portfolioId
                projectId
                role
            }
        }
    `,
};

export type PortfolioListItem = {
    portfolio: SubPortfolio;

    /**
     * How many levels of sub-portfolio exists as descendants of this portfolio
     */
    getDescendantLevels: () => number;

    /**
     * All descendants as a flat list (but each keep own sub portfolios)
     */
    getDescendants: () => ReadonlyArray<SubPortfolio>;

    /**
     * All parents of this portfolio starting with the top-most parent
     * todo Should we instead return PortfolioListItem?
     */
    getAncestors: () => ReadonlyArray<SubPortfolio>;

    /**
     * All parents of this portfolio starting with the top-most parent, including self as the last portfolio
     */
    getAncestorsAndSelf(): ReadonlyArray<SubPortfolio>;
};
type UsePortfolioListOptions = {
    /**
     * If included, there will be a list of granted roles for this user on
     * each portfolio item.
     */
    pqiUser?: usePortfolioList_pqiUser$key;
    /**
     * The main portfolio will be included in the results, but with a twist.
     * => The name, path will come from the team
     */
    includeMainPortfolio?: boolean;
};
export const usePortfolioList = (
    teamRef: usePortfolioList_team$key | null,
    options?: UsePortfolioListOptions,
): Array<PortfolioListItem> => {
    const team = useFragment(fragments.team, teamRef);
    const pqiUser = useFragment(fragments.pqiUser, options?.pqiUser);

    const includeMainPortfolio = options?.includeMainPortfolio;

    return useMemo(() => {
        const isGranted: IsGrantedFunction = (matcher) => {
            return pqiUser?.grants?.some(matcher);
        };

        return createPortfolioList(team, isGranted, includeMainPortfolio);
    }, [team, pqiUser, includeMainPortfolio]);
};

export function createPortfolioList(
    team: usePortfolioList_team$data | null,
    isGranted: IsGrantedFunction,
    includeMainPortfolio?: boolean,
): PortfolioListItem[] {
    if (!team) {
        return [];
    }
    const { mainPortfolio } = team;

    const portfolios = [
        ...mainPortfolio.subPortfolios.map((portfolio) => ({
            ...portfolio,
            subPortfolios: [] as Array<SubPortfolio>,
            grantedRoles: [] as Array<PQiUserGrant['role']>,
        })),
    ];

    if (includeMainPortfolio) {
        portfolios.unshift({
            id: mainPortfolio.id,
            path: team.path,
            name: team.name,
            parent: {
                id: team.id,
            },
            subPortfolios: [],
            grantedRoles: [],
        });
    }

    const rootGrants: Array<PQiUserGrant['role']> = [];
    isGranted((grant) => {
        if (
            (grant.portfolioId &&
                grant.portfolioId !== team.mainPortfolio.id) ||
            grant.projectId
        ) {
            return false;
        }
        if (
            (grant.teamId === team.id || !grant.teamId) &&
            !rootGrants.includes(grant.role)
        ) {
            rootGrants.push(grant.role);
        }
        return false;
    });

    const getParent = (portfolio: SubPortfolio): SubPortfolio | null =>
        portfolios
            .filter((parent) => parent.id === portfolio.parent?.id)
            .pop() ?? null;
    const getChildren = (portfolio) =>
        portfolios.filter((child) => child.parent.id === portfolio.id);

    const getAncestors = (portfolio: SubPortfolio): SubPortfolio[] => {
        const parent = getParent(portfolio);
        if (parent) {
            return [...getAncestors(parent), parent];
        }
        return [];
    };
    const getDescendants = (portfolio) => {
        const children = getChildren(portfolio);
        return [
            ...children.flatMap((child) => [child, ...getDescendants(child)]),
        ];
    };

    const getGrantedRoles = (
        portfolio: SubPortfolio,
    ): Array<PQiUserGrant['role']> => {
        const grantedRoles = [...rootGrants];
        isGranted((grant) => {
            if (
                grant.portfolioId === portfolio.id &&
                !grantedRoles.includes(grant.role)
            ) {
                grantedRoles.push(grant.role);
            }
            return false;
        });
        const parent = getParent(portfolio);
        if (parent && parent.id !== mainPortfolio.id) {
            for (const roleName of getGrantedRoles(parent)) {
                if (!grantedRoles.includes(roleName)) {
                    grantedRoles.push(roleName);
                }
            }
        }
        return grantedRoles;
    };
    return portfolios
        .map((portfolio) => {
            portfolio.subPortfolios = getChildren(portfolio);
            portfolio.grantedRoles = getGrantedRoles(portfolio);
            const descendants = getDescendants(portfolio);
            const ancestors = getAncestors(portfolio);
            return {
                portfolio,
                getDescendants() {
                    return descendants;
                },
                getAncestors() {
                    return ancestors;
                },
                getAncestorsAndSelf() {
                    return [...ancestors, portfolio];
                },
                getDescendantLevels() {
                    return getMaxDescendantsLevel(
                        portfolio as {
                            subPortfolios: ReadonlyArray<SubPortfolio>;
                        },
                    );
                },
            };
        })
        .sort((a, b) => {
            const fullNameA = [...a.getAncestors(), a.portfolio]
                .map(({ name }) => name)
                .join('/');
            const fullNameB = [...b.getAncestors(), b.portfolio]
                .map(({ name }) => name)
                .join('/');
            return naturalCompare(fullNameA, fullNameB);
        });
}

export default usePortfolioList;
