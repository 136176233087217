let counter = 0;

/**
 * Simple unique id generator. It returns a sequential number starting at 1 and the use-case is
 * when you need a DOM element identifier.
 *
 * @returns {number}
 */
export default function uniqueId() {
    return ++counter;
}
