import type * as React from 'react';
import {
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    selectClasses,
} from '@mui/material';
import type { FormControlProps } from '@mui/material';
import { useIntl } from 'react-intl';
import type { TeamMemberRoleInput, TeamMemberRoleName } from '@/schema.graphql';
import { roleSelectOptions } from './RoleSelect.selectOptions';
import type { RoleSelectOption } from './RoleSelect.selectOptions';

export interface Role extends Omit<TeamMemberRoleInput, 'name'> {
    name: TeamMemberRoleName | null;

    readonly removed?: true;
}

export type RoleSelectProps = {
    readonly value?: RoleSelectOption['value'];
    readonly FormControlProps?: Omit<FormControlProps, 'onChange'>;

    // Limit the available options to having resource types specified in the selected option
    readonly limitResourceTypes?: boolean;
    onChange(value: RoleSelectOption['value']): void;
    disabled?: boolean;
};

export const RoleSelect: React.FC<RoleSelectProps> = (props) => {
    const { value, onChange, limitResourceTypes } = props;
    const { formatMessage } = useIntl();
    const label = formatMessage({ defaultMessage: 'Role' });

    const selectedOption = roleSelectOptions.find((o) => o.value === value);

    return (
        <FormControl fullWidth {...props.FormControlProps}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                disabled={props.disabled}
                value={value ?? ''}
                onChange={(event) =>
                    onChange(event.target.value as TeamMemberRoleName)
                }
                renderValue={() =>
                    selectedOption ? formatMessage(selectedOption.name) : ''
                }
                sx={{
                    [`& .${selectClasses.select}`]: {
                        whiteSpace: 'normal!important',
                    },
                }}
            >
                {roleSelectOptions
                    .filter((option) => {
                        if (option.deprecated && option.value !== value) {
                            return false;
                        }
                        if (
                            limitResourceTypes &&
                            !selectedOption?.resourceTypes.some(
                                (resourceType) =>
                                    option.resourceTypes.includes(resourceType),
                            )
                        ) {
                            return false;
                        }
                        return true;
                    })
                    .map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <ListItemText
                                sx={{ p: 0, m: 0 }}
                                primary={formatMessage(option.name)}
                                secondary={formatMessage(option.description)}
                                secondaryTypographyProps={{
                                    color: option.deprecated
                                        ? 'warning.main'
                                        : 'text.secondary',
                                }}
                            />
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};
