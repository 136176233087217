/**
 * @generated SignedSource<<dab4cf3180e98911111c032b1b0c96fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TeamMemberRoleName = "PROJECT_CREATOR" | "PROJECT_MANAGER" | "RESULTS_VIEWER" | "SURVEY_ACTIVATOR" | "SURVEY_ADMIN" | "SURVEY_ADMINISTRATOR" | "SURVEY_PLANNER" | "TEAM_ADMIN" | "TEAM_OWNER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditTeamMemberForm_member$data = {
  readonly roles: ReadonlyArray<{
    readonly name: TeamMemberRoleName;
    readonly portfolioId?: string;
    readonly projectId?: string;
  }>;
  readonly user: {
    readonly emailAddress: string;
    readonly fullName: string;
    readonly id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TeamMemberNotifications_member">;
  readonly " $fragmentType": "EditTeamMemberForm_member";
};
export type EditTeamMemberForm_member$key = {
  readonly " $data"?: EditTeamMemberForm_member$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTeamMemberForm_member">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTeamMemberForm_member",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TeamMemberNotifications_member"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectId",
              "storageKey": null
            }
          ],
          "type": "TeamMemberProjectRole",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "portfolioId",
              "storageKey": null
            }
          ],
          "type": "TeamMemberPortfolioRole",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TeamMember",
  "abstractKey": null
};

(node as any).hash = "550e51a0d06bd8d6edbcc7e79c6bb5a8";

export default node;
