/**
 * @generated SignedSource<<c90cbb506481081e8b4157961bfc674a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioSelect_team$data = {
  readonly mainPortfolio: {
    readonly id: string;
  };
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"usePortfolioList_team">;
  readonly " $fragmentType": "PortfolioSelect_team";
};
export type PortfolioSelect_team$key = {
  readonly " $data"?: PortfolioSelect_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioSelect_team">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioSelect_team",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePortfolioList_team"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MainPortfolio",
      "kind": "LinkedField",
      "name": "mainPortfolio",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};

(node as any).hash = "0d1807490d343c64c569e96d3d25c82c";

export default node;
