/**
 * @generated SignedSource<<4093923327bd8f3e5973e49716f9bde3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamMemberRoleTableRow_team$data = {
  readonly id: string;
  readonly mainPortfolio: {
    readonly id: string;
  };
  readonly name: string;
  readonly path: string;
  readonly portfolio: {
    readonly projects: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly path: string;
    }>;
  } | null | undefined;
  readonly portfolios: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioSelect_team">;
  readonly " $fragmentType": "TeamMemberRoleTableRow_team";
};
export type TeamMemberRoleTableRow_team$key = {
  readonly " $data"?: TeamMemberRoleTableRow_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamMemberRoleTableRow_team">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamMemberRoleTableRow_team",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioSelect_team"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SubPortfolio",
      "kind": "LinkedField",
      "name": "portfolios",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MainPortfolio",
      "kind": "LinkedField",
      "name": "mainPortfolio",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "portfolio",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "22b774bb22d52f824a7b4c8315de5ea3";

export default node;
