/**
 * @generated SignedSource<<1cd1331c57e5a236dd377d5de4dbea73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TeamMemberRoleName = "PROJECT_CREATOR" | "PROJECT_MANAGER" | "RESULTS_VIEWER" | "SURVEY_ACTIVATOR" | "SURVEY_ADMIN" | "SURVEY_ADMINISTRATOR" | "SURVEY_PLANNER" | "TEAM_ADMIN" | "TEAM_OWNER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePortfolioList_pqiUser$data = {
  readonly grants: ReadonlyArray<{
    readonly portfolioId: string | null | undefined;
    readonly projectId: string | null | undefined;
    readonly role: TeamMemberRoleName;
    readonly teamId: string | null | undefined;
  }>;
  readonly " $fragmentType": "usePortfolioList_pqiUser";
};
export type usePortfolioList_pqiUser$key = {
  readonly " $data"?: usePortfolioList_pqiUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePortfolioList_pqiUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePortfolioList_pqiUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PQiUserGrant",
      "kind": "LinkedField",
      "name": "grants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portfolioId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PQiUser",
  "abstractKey": null
};

(node as any).hash = "deb6c3e21fcb99dffa02814a0bef17c5";

export default node;
