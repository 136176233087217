import { Box, Button, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import type { HelpCenterStartPageQuery$data } from '../__generated__/HelpCenterStartPageQuery.graphql';

const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: 14,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
        fontSize: 16,
    },
}));

export type StartPageTopicBlockProps = {
    topic: HelpCenterStartPageQuery$data['topics'][0];
    articles: HelpCenterStartPageQuery$data['articles'];
};

function StartPageTopicBlock(props: StartPageTopicBlockProps) {
    const { articles, topic } = props;

    return (
        <Box
            sx={(theme) => ({
                borderRadius: 0.5,
                boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.08)',
                backgroundColor:
                    theme.palette.mode === 'light'
                        ? theme.bino.color.backGroundGray
                        : theme.palette.background.paper,
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                [theme.breakpoints.up('md')]: {
                    p: 2,
                },
                [theme.breakpoints.up('lg')]: {
                    p: 3,
                },
            })}
        >
            <Box
                component="h3"
                sx={(theme) => ({
                    fontSize: 18,
                    m: 0,
                    p: 0,
                    color: theme.palette.text.primary,
                })}
            >
                {topic.name}
            </Box>

            <Box sx={{ py: 2 }} />
            {articles.map((article, i) => (
                <StyledLink key={i} to={`${topic.url}#${article.url}`}>
                    {article.title}
                </StyledLink>
            ))}

            <Box mt="auto">
                <Button
                    component={Link}
                    to={`${topic.url}`}
                    sx={{ minWidth: 'auto', ml: -1 }}
                >
                    <FormattedMessage defaultMessage="More" />
                </Button>
            </Box>
        </Box>
    );
}

export default StartPageTopicBlock;
