/**
 * @generated SignedSource<<59b23e77e2ff8b176dea60ad8278f05f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PQiTeamMemberNotificationType = "ProjectSurveyCompleted" | "ProjectSurveyStarted" | "%future added value";
export type ToggleTeamMemberNotificationInput = {
  enabled: boolean;
  portfolioId?: string | null | undefined;
  teamId: string;
  type: PQiTeamMemberNotificationType;
  userId: string;
};
export type TeamMemberNotificationsToggleMutation$variables = {
  input: ToggleTeamMemberNotificationInput;
};
export type TeamMemberNotificationsToggleMutation$data = {
  readonly toggleTeamMemberNotification: {
    readonly teamMember: {
      readonly enabledNotifications: ReadonlyArray<{
        readonly portfolioId: string | null | undefined;
        readonly type: PQiTeamMemberNotificationType | null | undefined;
      }>;
    } | null | undefined;
  };
};
export type TeamMemberNotificationsToggleMutation = {
  response: TeamMemberNotificationsToggleMutation$data;
  variables: TeamMemberNotificationsToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamMemberNotification",
  "kind": "LinkedField",
  "name": "enabledNotifications",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portfolioId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamMemberNotificationsToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleTeamMemberNotificationPayload",
        "kind": "LinkedField",
        "name": "toggleTeamMemberNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamMember",
            "kind": "LinkedField",
            "name": "teamMember",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamMemberNotificationsToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleTeamMemberNotificationPayload",
        "kind": "LinkedField",
        "name": "toggleTeamMemberNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamMember",
            "kind": "LinkedField",
            "name": "teamMember",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "497410e0da862d2117cb34fe80e1d5a3",
    "id": null,
    "metadata": {},
    "name": "TeamMemberNotificationsToggleMutation",
    "operationKind": "mutation",
    "text": "mutation TeamMemberNotificationsToggleMutation(\n  $input: ToggleTeamMemberNotificationInput!\n) {\n  toggleTeamMemberNotification(input: $input) {\n    teamMember {\n      enabledNotifications {\n        type\n        portfolioId\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8aac84175c4843f494a5c2bfc47dbcc2";

export default node;
