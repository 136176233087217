/**
 * @generated SignedSource<<0dbe409a82911e6d77b7e646166bc9fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TeamMemberRoleName = "PROJECT_CREATOR" | "PROJECT_MANAGER" | "RESULTS_VIEWER" | "SURVEY_ACTIVATOR" | "SURVEY_ADMIN" | "SURVEY_ADMINISTRATOR" | "SURVEY_PLANNER" | "TEAM_ADMIN" | "TEAM_OWNER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MainMenu_pqiUser$data = {
  readonly grants: ReadonlyArray<{
    readonly portfolioId: string | null | undefined;
    readonly projectId: string | null | undefined;
    readonly role: TeamMemberRoleName;
    readonly teamId: string | null | undefined;
  }>;
  readonly " $fragmentType": "MainMenu_pqiUser";
};
export type MainMenu_pqiUser$key = {
  readonly " $data"?: MainMenu_pqiUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainMenu_pqiUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainMenu_pqiUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PQiUserGrant",
      "kind": "LinkedField",
      "name": "grants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portfolioId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PQiUser",
  "abstractKey": null
};

(node as any).hash = "1a0e1e83d5e5a51dcbacef47db052b46";

export default node;
