/**
 * @generated SignedSource<<9c2dcf65a050dbb5fd92e559139683a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useOnlyMyProjects_team$data = {
  readonly id: string;
  readonly path: string;
  readonly " $fragmentType": "useOnlyMyProjects_team";
};
export type useOnlyMyProjects_team$key = {
  readonly " $data"?: useOnlyMyProjects_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOnlyMyProjects_team">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOnlyMyProjects_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};

(node as any).hash = "0fbd1d23303cff7f1096a984b954ba58";

export default node;
