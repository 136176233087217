// @ts-strict-ignore
import type * as React from 'react';
import { styled } from '@mui/material';
import type { FormControlProps } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import type { TeamMemberRoleInput } from '@/schema.graphql';
import { PortfolioSelect } from '../PortfolioSelect';
import { RoleSelect } from './RoleSelect.tsx';
import type { RoleSelectProps } from './RoleSelect.tsx';
import {
    findRoleSelectOption,
    roleSelectOptions,
} from './RoleSelect.selectOptions.ts';
import type { RoleSelectWithPortfolioOrProject_team$key } from './__generated__/RoleSelectWithPortfolioOrProject_team.graphql';
const SelectedRoleDescription = styled('div')(({ theme }) => ({
    color: theme.bino.color.gray,
    fontSize: 14,
}));

export interface Role extends TeamMemberRoleInput {
    readonly removed?: true;
}

export interface RoleSelectWithPortfolioOrProjectProps {
    readonly role?: Role;
    readonly FormControlProps?: Omit<FormControlProps, 'onChange'>;

    onChange(role: Role): void;

    readonly team: RoleSelectWithPortfolioOrProject_team$key;
}

const RoleSelectWithPortfolioOrProject: React.FC<
    RoleSelectWithPortfolioOrProjectProps
> = (props) => {
    const { formatMessage } = useIntl();
    const team = useFragment(fragments.team, props.team);
    const { role, onChange } = props;
    // const { formatMessage } = useIntl();
    const onChangeRole: RoleSelectProps['onChange'] = (value) => {
        const selectedRoleOption = roleSelectOptions.find(
            (o) => o.value === value,
        );
        onChange({
            ...role,
            name: selectedRoleOption.value,
        });
    };
    const onChangePortfolioId = (portfolioId: string) => {
        onChange({
            ...role,
            portfolioId:
                portfolioId === team.mainPortfolio.id ? null : portfolioId,
        });
    };
    const selectedRoleOption = findRoleSelectOption(role);

    const selectPortfolioLabel = (
        <FormattedMessage defaultMessage="Portfolio" />
    );
    return (
        <>
            <RoleSelect
                value={selectedRoleOption?.value}
                onChange={onChangeRole}
                FormControlProps={props.FormControlProps}
            />
            <SelectedRoleDescription>
                {selectedRoleOption ? (
                    formatMessage(selectedRoleOption.description)
                ) : (
                    <span>&nbsp;</span>
                )}
            </SelectedRoleDescription>
            {selectedRoleOption?.resourceTypes.includes('portfolio') &&
                !role.projectId && (
                    <PortfolioSelect
                        team={team}
                        label={selectPortfolioLabel}
                        portfolioId={role?.portfolioId ?? team.mainPortfolio.id}
                        onChange={onChangePortfolioId}
                        fullWidth
                        {...props.FormControlProps}
                    />
                )}
        </>
    );
};

const fragments = {
    team: graphql`
        fragment RoleSelectWithPortfolioOrProject_team on Team {
            ...PortfolioSelect_team
            mainPortfolio {
                id
            }
            portfolio {
                projects {
                    id
                    name
                }
            }
        }
    `,
};

export default RoleSelectWithPortfolioOrProject;
