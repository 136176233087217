// @ts-strict-ignore
const goDeeper = <
    SubPortfolio extends {
        subPortfolios?: ReadonlyArray<SubPortfolio>;
    },
>(
    portfolio: SubPortfolio,
    level: number,
) => {
    const subPortfolios = portfolio.subPortfolios;
    if (subPortfolios && subPortfolios.length !== 0) {
        return subPortfolios.map((sp) => goDeeper(sp, level + 1));
    }
    return level;
};

export default function getMaxDescendantsLevel<
    SubPortfolio extends {
        subPortfolios?: ReadonlyArray<SubPortfolio>;
    },
>(portfolio: SubPortfolio): number {
    const allLevelsOrLevel = goDeeper(portfolio, 0);

    if (typeof allLevelsOrLevel !== 'number') {
        return Math.max(...[0, ...allLevelsOrLevel.flat()]);
    }
    return Math.max(0, allLevelsOrLevel);
}
