import { defineMessages } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';
import { TeamMemberRoleName as RoleName } from '@/schema.graphql';

export const teamRolesMessages: Partial<
    Record<RoleName, Record<'name' | 'description', MessageDescriptor>>
> = {
    [RoleName.TeamOwner]: defineMessages({
        name: {
            defaultMessage: 'Team owner',
            description: 'Role name: team-owner',
        },
        description: {
            defaultMessage:
                'The team owner role should no longer be used, please select another role',
        },
    }),
    [RoleName.TeamAdmin]: defineMessages({
        name: {
            defaultMessage: 'Main administrator',
            description: 'Role name: main-administrator',
        },
        description: {
            defaultMessage:
                'Full access to all functions, including team settings.',
            description: 'Role description: main-administrator',
        },
    }),
    // NOTE The same role: "SurveyAdmin" is used also for administators in projects
    [RoleName.SurveyAdmin]: defineMessages({
        name: {
            defaultMessage: 'Portfolio administrator',
            description: 'Role name: portfolio-administrator',
        },
        description: {
            defaultMessage:
                'Create and administer all projects in the selected portfolio.',
            description: 'Role description: portfolio-administrator',
        },
    }),
    // can create projects in the selected team/portfolio, and becomes a project administrator in the projects that they create
    [RoleName.ProjectCreator]: defineMessages({
        name: {
            defaultMessage: 'Project creator',
            description: 'Role name: project-creator',
        },
        description: {
            defaultMessage: 'Create projects in the selected portfolio.',
            description: 'Role description: project-creator',
        },
    }),
    [RoleName.ResultsViewer]: defineMessages({
        name: {
            defaultMessage: 'Can view results',
            description: 'Role name: can-view-results',
        },
        description: {
            defaultMessage:
                'See results and work with improvement activities for all projects in the selected portfolio.',
            description: 'Role description: can-view-results',
        },
    }),
};

export const projectRolesMessages: Partial<
    Record<RoleName, Record<'name', MessageDescriptor>>
> = {
    [RoleName.SurveyAdmin]: defineMessages({
        name: {
            defaultMessage: 'Administrator',
            description: 'SurveyAdmin for a project',
        },
    }),
    [RoleName.ResultsViewer]: defineMessages({
        name: {
            defaultMessage: 'Results and improvement work',
            description: 'ResultsViewer for a project',
        },
    }),
};
