/**
 * @generated SignedSource<<afda7f9f86a82c38fc0c7002517fbd5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioBreadcrumbs_team$data = {
  readonly id: string;
  readonly name: string;
  readonly path: string;
  readonly " $fragmentSpreads": FragmentRefs<"usePortfolioList_team">;
  readonly " $fragmentType": "PortfolioBreadcrumbs_team";
};
export type PortfolioBreadcrumbs_team$key = {
  readonly " $data"?: PortfolioBreadcrumbs_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioBreadcrumbs_team">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioBreadcrumbs_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePortfolioList_team"
    }
  ],
  "type": "Team",
  "abstractKey": null
};

(node as any).hash = "83cabd7e57de02c6689041c2d34f20e0";

export default node;
