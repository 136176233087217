/**
 * @generated SignedSource<<1f89854685b22e92997d72bd58fc4079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PQiTeamMemberNotificationType = "ProjectSurveyCompleted" | "ProjectSurveyStarted" | "%future added value";
export type TeamMemberRoleName = "PROJECT_CREATOR" | "PROJECT_MANAGER" | "RESULTS_VIEWER" | "SURVEY_ACTIVATOR" | "SURVEY_ADMIN" | "SURVEY_ADMINISTRATOR" | "SURVEY_PLANNER" | "TEAM_ADMIN" | "TEAM_OWNER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TeamMemberNotifications_member$data = {
  readonly enabledNotifications: ReadonlyArray<{
    readonly portfolioId: string | null | undefined;
    readonly type: PQiTeamMemberNotificationType | null | undefined;
  }>;
  readonly roles: ReadonlyArray<{
    readonly name: TeamMemberRoleName;
    readonly portfolioId?: string;
  }>;
  readonly user: {
    readonly id: string;
  };
  readonly " $fragmentType": "TeamMemberNotifications_member";
};
export type TeamMemberNotifications_member$key = {
  readonly " $data"?: TeamMemberNotifications_member$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamMemberNotifications_member">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "portfolioId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamMemberNotifications_member",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamMemberNotification",
      "kind": "LinkedField",
      "name": "enabledNotifications",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "TeamMemberPortfolioRole",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TeamMember",
  "abstractKey": null
};
})();

(node as any).hash = "95d7b346d9b944e746f39e1d87bd3753";

export default node;
