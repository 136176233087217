/**
 * @generated SignedSource<<07137c3615841612412fadc94f3bf853>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamSelfRegistrationFormQuery$variables = Record<PropertyKey, never>;
export type TeamSelfRegistrationFormQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"useIsAdmin_user">;
  } | null | undefined;
};
export type TeamSelfRegistrationFormQuery = {
  response: TeamSelfRegistrationFormQuery$data;
  variables: TeamSelfRegistrationFormQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamSelfRegistrationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useIsAdmin_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TeamSelfRegistrationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8cf55eea4c9317d6acedbe732dd150b",
    "id": null,
    "metadata": {},
    "name": "TeamSelfRegistrationFormQuery",
    "operationKind": "query",
    "text": "query TeamSelfRegistrationFormQuery {\n  me {\n    ...useIsAdmin_user\n    id\n  }\n}\n\nfragment useIsAdmin_user on User {\n  emailAddress\n}\n"
  }
};

(node as any).hash = "044fedc61771328f220040ff062fa12b";

export default node;
