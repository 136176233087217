/**
 * @generated SignedSource<<707d1414df6c3d0c5f1254070107c684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamMemberNotifications_team$data = {
  readonly id: string;
  readonly mainPortfolio: {
    readonly id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioSelect_team" | "usePortfolioList_team">;
  readonly " $fragmentType": "TeamMemberNotifications_team";
};
export type TeamMemberNotifications_team$key = {
  readonly " $data"?: TeamMemberNotifications_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamMemberNotifications_team">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamMemberNotifications_team",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MainPortfolio",
      "kind": "LinkedField",
      "name": "mainPortfolio",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePortfolioList_team"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioSelect_team"
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "cd1f0a117133564b924839de3e9bb006";

export default node;
