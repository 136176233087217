import { graphql, useFragment } from 'react-relay';
import { StructuredText } from 'react-datocms';
import YouTube from 'react-youtube';
import { Link } from 'react-router';
import ArticleLinkRecord from './ArticleLinkRecord';
import ImageRecord from './image-record';
import PreambleRecord from './PreambleRecord';
import { StyledText } from './TextRecord';
import HeadingRecord from './HeadingRecord';
import type { ArticleContent_articleContent$key } from './__generated__/ArticleContent_articleContent.graphql';

// TODO:
// - Check the StructuredText component of datocms. The inline text doesnt appear. I think we need to add config in the StructuredText.
// - Check the topic title. Whenever we change the language to swedish, the title doesnt switch to swedish but the other information like description and articles change.

export default function ArticleContent(props: {
    articleContent: ArticleContent_articleContent$key;
}) {
    const articleContent = useFragment(
        fragments.articleContent,
        props.articleContent,
    );
    return (
        <StyledText as="div">
            <StructuredText
                data={articleContent as any}
                renderBlock={(block) => {
                    const record: (typeof articleContent)['blocks'][0] =
                        block.record as any;
                    switch (block.record.__typename) {
                        case 'HeadingRecord':
                            return <HeadingRecord content={record} />;
                        case 'ImageRecord':
                            return <ImageRecord content={record} />;
                        case 'ArticleLinkRecord':
                            return <ArticleLinkRecord content={record} />;
                        case 'PreambleRecord':
                            return <PreambleRecord content={record} />;
                        case 'VideoRecord': {
                            const { video } = record;
                            switch (video.provider) {
                                case 'youtube':
                                    // todo Responsiveness
                                    return (
                                        <div
                                            style={{
                                                position: 'relative',
                                                // paddingTop:
                                                //         (video.width /
                                                //     100 /
                                                //             video.height) +
                                                //     '%',
                                            }}
                                        >
                                            <YouTube
                                                // className={
                                                //     classes.videoPlayer
                                                // }
                                                opts={
                                                    {
                                                        // width: '100%',
                                                        // height: '100%',
                                                    }
                                                }
                                                videoId={video.providerUid}
                                            />
                                        </div>
                                    );
                                default:
                                    return (
                                        <div>
                                            Unsupported video link:{' '}
                                            <pre>
                                                {JSON.stringify(
                                                    record.video,
                                                    null,
                                                    2,
                                                )}
                                            </pre>
                                        </div>
                                    );
                            }
                        }
                        default:
                            return null;
                    }
                }}
                renderLinkToRecord={(context) => {
                    const record =
                        context.record as (typeof articleContent)['links'][number];
                    switch (record.__typename) {
                        // case 'ArticleRecord': {
                        //     return (
                        //         <a href={`#${record.url}`}>{record.title}</a>
                        //     );
                        // }
                        case 'HelpCenterArticleRecord': {
                            // Add `HelpCenterArticleRecord` to be able we can "render" the inline link in the article content.
                            return (
                                <Link to={`${record.topic.url}#${record.url}`}>
                                    {context.children.toString()}
                                </Link>
                            );
                        }
                        default:
                            return null;
                    }
                }}
            />
        </StyledText>
    );
}

const fragments = {
    articleContent: graphql`
        fragment ArticleContent_articleContent on HelpCenterArticleModelArticleContentField {
            value
            links {
                __typename
                id
                url
                title
                topic {
                    url
                }
            }
            blocks {
                __typename
                ...HeadingRecord_content
                ...ArticleLinkRecord_content
                ...ImageRecord_content
                ...PreambleRecord_content
                ...TextRecord_content
                ... on HeadingRecord {
                    id
                }
                ... on ArticleLinkRecord {
                    id
                }
                ... on ImageRecord {
                    id
                }
                ... on PreambleRecord {
                    id
                }
                ... on VideoRecord {
                    id
                    video {
                        provider
                        title
                        providerUid
                        url
                        width
                        height
                    }
                }
                ... on TextRecord {
                    id
                }
            }
        }
    `,
};
