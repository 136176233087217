import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemButton,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router';
import type { PortfolioListItem } from '@/features/team';
import {
    getLastSectionPath,
    getPortfolioFullPathFromArray,
} from '../../RootPaths';
import getTheTail from '../Breadcrumbs/utils/getTheTail';
import { InformationTooltip } from '../InformationTooltip';
import {
    useOnlyMyProjects,
    useOnlyMyProjectsCheckboxEnabled,
    useOnlyMyProjectsFeatureEnabled,
} from '../../utils/useOnlyMyProjects.ts';

export type PortfolioBreadcrumbsDropdownMenuProps = {
    /**
     * With the main portfolio included
     */
    portfolios: PortfolioListItem[];
};

const portfolioHasAccess = (portfolio: PortfolioListItem['portfolio']) =>
    portfolio.grantedRoles.some((role) =>
        [
            'TEAM_OWNER',
            'TEAM_ADMIN',
            'SURVEY_ADMIN',
            'PROJECT_CREATOR',
            'RESULTS_VIEWER',
        ].includes(role),
    );

function PortfolioBreadcrumbsDropdownMenu(
    props: PortfolioBreadcrumbsDropdownMenuProps,
) {
    const { portfolios } = props;
    const location = useLocation();
    const params = useParams();
    const { formatMessage } = useIntl();
    const [onlyMyProjects, setOnlyMyProjects] = useOnlyMyProjects();
    const onlyMyProjectsCheckboxEnabled = useOnlyMyProjectsCheckboxEnabled();
    const onlyMyProjectsFeatureEnabled = useOnlyMyProjectsFeatureEnabled();

    const currentLastPortfolio = getLastSectionPath(params);
    // NOTE Some url's does not exist in all portfolios, like specific surveys and projects. In this case you'll be redirected
    const tail = getTheTail(location.pathname, currentLastPortfolio);
    const listItems = [
        ...portfolios
            .filter((item) => {
                if (portfolioHasAccess(item.portfolio)) {
                    return true;
                }
                if (item.getDescendants().some(portfolioHasAccess)) {
                    // In this case, we might want to visually indicate this, so
                    // maybe move this to the .map()
                    return true;
                }
                // console.log('No access to ', portfolio, portfolio.getDescendants());
                return false;
            })

            .map((item) => {
                const parents = item.getAncestors();
                const paths = [
                    ...parents.map(({ path }) => path),
                    item.portfolio.path,
                ];
                return {
                    name: item.portfolio.name,
                    level: 1 + parents.length,
                    path: `/${getPortfolioFullPathFromArray(paths)}`,
                };
            }),
    ];

    return (
        <List disablePadding sx={{ maxHeight: '80vh', overflow: 'auto' }}>
            {onlyMyProjectsFeatureEnabled && onlyMyProjectsCheckboxEnabled && (
                <ListItem onClick={(e) => e.stopPropagation()}>
                    <FormControlLabel
                        label={
                            <Box display="inline-flex" alignItems="center">
                                <span>
                                    {formatMessage({
                                        defaultMessage: 'Only my projects',
                                        description:
                                            'Checkbox label to enable showing only "my projects"',
                                    })}
                                </span>
                                <IconButton>
                                    <InformationTooltip
                                        title={formatMessage({
                                            defaultMessage:
                                                'Only projects where you have been assigned a role',
                                            description:
                                                'Short help text to explain what "my projects" is',
                                        })}
                                    />
                                </IconButton>
                            </Box>
                        }
                        control={
                            <Checkbox
                                checked={onlyMyProjects}
                                onChange={() =>
                                    setOnlyMyProjects(!onlyMyProjects)
                                }
                            />
                        }
                    />
                </ListItem>
            )}
            {listItems.map(({ name, level, path }, index) => (
                <ListItemButton
                    key={index}
                    sx={(theme) => ({
                        pl: level * 2,
                        boxShadow:
                            level === 1
                                ? `0 1px 1px ${theme.bino.color.boxShadowBorder}`
                                : undefined,
                    })}
                    component={Link}
                    to={path + (tail ? `/${tail}` : '')}
                >
                    {name}
                </ListItemButton>
            ))}
        </List>
    );
}

export default PortfolioBreadcrumbsDropdownMenu;
