import type { FunctionComponent } from 'react';
import { graphql } from 'react-relay';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router';
import { styled } from '@mui/material';
import { Paper, BackIcon, TitleAndSubTitle } from '@/components';
import { useScrollToTop } from '@/services/useScrollToTop';
import TopicArticles from './components/TopicArticles';
import HelpCenterPageWithQueryRenderer from './components/HelpCenterPageWithQueryRenderer';
import { useSiteLocale } from './hooks/useSiteLocale';
import Breadcrumb from './components/Breadcrumb';
import type { HelpCenterTopicPageQuery } from './__generated__/HelpCenterTopicPageQuery.graphql';

const BackLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '1px',
    fontWeight: 'bold',
    textTransform: 'uppercase',

    '& > svg': {
        marginRight: 8,
        verticalAlign: 'middle',
    },

    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

const ArticlesSection = styled('section')(({ theme }) => ({
    // Important, must set this counter for articles to be numbered correctly
    counterSet: 'topic-article',
    display: 'grid',
    padding: 0,
    rowGap: '40px',
    marginTop: 30,

    [theme.breakpoints.up('sm')]: {
        padding: '40px 70px',
        rowGap: '55px',
        marginTop: 0,
    },
}));

const HelpCenterTopicPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    const params = useParams<{ topic: string }>();
    const variables = {
        topicUrl: params.topic,
        locale: useSiteLocale(),
    };
    useScrollToTop();

    return (
        <HelpCenterPageWithQueryRenderer<HelpCenterTopicPageQuery>
            query={query}
            variables={variables}
            renderBreadcrumb={(props) => (
                <Breadcrumb helpCenterUrl={'..'} topic={props.topic} />
            )}
            renderContent={(props) => {
                const { topic } = props;

                return (
                    <Paper responsive>
                        <BackLink to={'..'}>
                            <BackIcon size={14} />
                            {formatMessage({
                                defaultMessage: 'Back',
                                description: 'Back-link',
                            })}
                        </BackLink>
                        <ArticlesSection>
                            <header>
                                <TitleAndSubTitle
                                    title={topic.name}
                                    subTitle={topic.description}
                                />
                            </header>
                            {topic && (
                                <TopicArticles
                                    topicId={props.topic.id as string}
                                />
                            )}
                        </ArticlesSection>
                    </Paper>
                );
            }}
        />
    );
};

const query = graphql`
    query HelpCenterTopicPageQuery($topicUrl: String!, $locale: SiteLocale) {
        topic: helpCenterTopic(
            locale: $locale
            filter: { url: { eq: $topicUrl } }
        ) {
            id
            name
            description
            url
            ...Breadcrumb_topic
        }
    }
`;

export default HelpCenterTopicPage;
