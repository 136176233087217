/**
 * @generated SignedSource<<65a61ab8985ae6e65a47d1b9b5a15a21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamMemberFormQuery$variables = {
  teamId: string;
};
export type TeamMemberFormQuery$data = {
  readonly team: {
    readonly " $fragmentSpreads": FragmentRefs<"AddTeamMemberForm_team" | "EditTeamMemberForm_team">;
  } | null | undefined;
};
export type TeamMemberFormQuery = {
  response: TeamMemberFormQuery$data;
  variables: TeamMemberFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "teamId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamMemberFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AddTeamMemberForm_team"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditTeamMemberForm_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamMemberFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MainPortfolio",
            "kind": "LinkedField",
            "name": "mainPortfolio",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubPortfolio",
                "kind": "LinkedField",
                "name": "subPortfolios",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "parent",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "projects",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubPortfolio",
            "kind": "LinkedField",
            "name": "portfolios",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "projects",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c6b9364ae7dcd0c5e7603821e6084599",
    "id": null,
    "metadata": {},
    "name": "TeamMemberFormQuery",
    "operationKind": "query",
    "text": "query TeamMemberFormQuery(\n  $teamId: ID!\n) {\n  team(id: $teamId) {\n    ...AddTeamMemberForm_team\n    ...EditTeamMemberForm_team\n    id\n  }\n}\n\nfragment AddTeamMemberForm_team on Team {\n  id\n  name\n  ...RoleSelectWithPortfolioOrProject_team\n}\n\nfragment EditTeamMemberForm_team on Team {\n  ...TeamMemberNotifications_team\n  ...RoleEditor_team\n  id\n  projects {\n    __typename\n    id\n    name\n  }\n  portfolios {\n    id\n    name\n  }\n}\n\nfragment PortfolioSelect_team on Team {\n  ...usePortfolioList_team\n  name\n  mainPortfolio {\n    id\n  }\n}\n\nfragment RoleEditor_team on Team {\n  ...PortfolioSelect_team\n  ...TeamMemberRoleTableRow_team\n  id\n  path\n  name\n  portfolios {\n    id\n    name\n  }\n  mainPortfolio {\n    id\n  }\n  portfolio {\n    __typename\n    projects {\n      __typename\n      id\n      path\n      name\n    }\n    id\n  }\n}\n\nfragment RoleSelectWithPortfolioOrProject_team on Team {\n  ...PortfolioSelect_team\n  mainPortfolio {\n    id\n  }\n  portfolio {\n    __typename\n    projects {\n      __typename\n      id\n      name\n    }\n    id\n  }\n}\n\nfragment TeamMemberNotifications_team on Team {\n  id\n  mainPortfolio {\n    id\n  }\n  ...usePortfolioList_team\n  ...PortfolioSelect_team\n}\n\nfragment TeamMemberRoleTableRow_team on Team {\n  ...PortfolioSelect_team\n  id\n  path\n  name\n  portfolios {\n    id\n    name\n  }\n  mainPortfolio {\n    id\n  }\n  portfolio {\n    __typename\n    projects {\n      __typename\n      id\n      path\n      name\n    }\n    id\n  }\n}\n\nfragment usePortfolioList_team on Team {\n  id\n  path\n  name\n  mainPortfolio {\n    id\n    subPortfolios {\n      id\n      path\n      name\n      parent {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a19f7455ac2269dc173cdd07ed8eae8";

export default node;
