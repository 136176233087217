import { Link, useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Button, PageNotFoundLayout, Paragraph } from '@/components';

export function ProjectSubpageNotFound() {
    const navigate = useNavigate();
    return (
        <PageNotFoundLayout
            hiddenFooter
            hiddenHeader
            actionButtons={
                <div>
                    <Paragraph>
                        <FormattedMessage
                            defaultMessage="<Button>Go to Surveys Results</Button>"
                            description="Go to surveys results tip"
                            values={{
                                Button: (chunks) => (
                                    <Button
                                        onClick={() => {
                                            navigate('.');
                                        }}
                                    >
                                        {chunks}
                                    </Button>
                                ),
                            }}
                        />
                    </Paragraph>
                    <Paragraph>
                        <FormattedMessage
                            defaultMessage="<Link>Visit Help Center</Link>"
                            description="Visit Help Center tip"
                            values={{
                                Link: (chunks) => (
                                    <Link to="../../help-center">{chunks}</Link>
                                ),
                            }}
                        />
                    </Paragraph>
                </div>
            }
        />
    );
}
