import { useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useFragment, graphql } from 'react-relay';
import { styled } from '@mui/material';
import ArticleContent from './ArticleContent';
import ArticleContentOld from './ArticleContentOld';
import type { Article_article$key } from './__generated__/Article_article.graphql';

const ArticleTitle = styled('h2')(({ theme }) => ({
    // Note: To be able the numbering of the article will work, one of its ancestor must specify the `counterSet`
    // css attrib and set the value to "topic-article".
    counterIncrement: 'topic-article',
    fontSize: 20,
    lineHeight: '28px',
    margin: '0',
    color: theme.palette.text.primary,

    '&::before': {
        content: 'counter(topic-article) "."',
        paddingRight: 6,
    },

    '& a': {
        color: theme.palette.text.primary,
    },
}));

export function Article(props: { article: Article_article$key }) {
    const article = useFragment(fragments.article, props.article);
    const { title, url } = article;
    const articleRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    useEffect(
        function scrollToArticleSection() {
            const { hash } = location;
            // Whenever there is new location object, we can assume there is changes on the url,
            // and the url has `hash` and the value is equal to Article's url, then scroll the
            // page to this Article section.
            if (Boolean(hash) && hash.slice(1) === url) {
                // Check the explanation -> https://www.npmjs.com/package/scroll-into-view-if-needed#usage
                scrollIntoView(articleRef.current, {
                    scrollMode: 'if-needed',
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        },
        [location, url],
    );

    return (
        <article id={url} ref={articleRef}>
            <header>
                <ArticleTitle>
                    <Link to={`#${url}`}>{title}</Link>
                </ArticleTitle>
            </header>
            {article.articleContent ? (
                <ArticleContent articleContent={article.articleContent} />
            ) : (
                article.content?.map((content, idx) => (
                    <ArticleContentOld key={idx} content={content} />
                ))
            )}
        </article>
    );
}

const fragments = {
    article: graphql`
        fragment Article_article on HelpCenterArticleRecord {
            id
            title
            url
            content {
                ...ArticleContentOld_content
            }
            articleContent {
                ...ArticleContent_articleContent
            }
        }
    `,
};
