/**
 * @generated SignedSource<<60904e0e8dc8c54739fb7caf1bdb246e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TeamMemberRoleName = "PROJECT_CREATOR" | "PROJECT_MANAGER" | "RESULTS_VIEWER" | "SURVEY_ACTIVATOR" | "SURVEY_ADMIN" | "SURVEY_ADMINISTRATOR" | "SURVEY_PLANNER" | "TEAM_ADMIN" | "TEAM_OWNER" | "%future added value";
export type GrantTeamMemberAccessInput = {
  email: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  name?: string | null | undefined;
  roles: ReadonlyArray<TeamMemberRoleInput>;
  teamId: string;
};
export type TeamMemberRoleInput = {
  name: TeamMemberRoleName;
  portfolioId?: string | null | undefined;
  projectId?: string | null | undefined;
};
export type AddTeamMemberMutation$variables = {
  input: GrantTeamMemberAccessInput;
};
export type AddTeamMemberMutation$data = {
  readonly grantTeamMemberAccess: {
    readonly team: {
      readonly members: ReadonlyArray<{
        readonly roles: ReadonlyArray<{
          readonly name: TeamMemberRoleName;
        }>;
        readonly user: {
          readonly emailAddress: string;
          readonly fullName: string;
          readonly id: string;
        };
      }>;
    } | null | undefined;
  };
};
export type AddTeamMemberMutation = {
  response: AddTeamMemberMutation$data;
  variables: AddTeamMemberMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddTeamMemberMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GrantTeamMemberAccessPayload",
        "kind": "LinkedField",
        "name": "grantTeamMemberAccess",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamMember",
                "kind": "LinkedField",
                "name": "members",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "roles",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddTeamMemberMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GrantTeamMemberAccessPayload",
        "kind": "LinkedField",
        "name": "grantTeamMemberAccess",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamMember",
                "kind": "LinkedField",
                "name": "members",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "roles",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fdbf159a609117ca1e9a03cb2199a4e2",
    "id": null,
    "metadata": {},
    "name": "AddTeamMemberMutation",
    "operationKind": "mutation",
    "text": "mutation AddTeamMemberMutation(\n  $input: GrantTeamMemberAccessInput!\n) {\n  grantTeamMemberAccess(input: $input) {\n    team {\n      members {\n        user {\n          id\n          fullName\n          emailAddress\n        }\n        roles {\n          __typename\n          name\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "96ea470fe96f89eadc1d232ba6d20d09";

export default node;
