import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { graphql, useFragment } from 'react-relay';
import type { useOnlyMyProjects_pqiUser$key } from './__generated__/useOnlyMyProjects_pqiUser.graphql.ts';
import type { useOnlyMyProjects_team$key } from './__generated__/useOnlyMyProjects_team.graphql.ts';

const localStorage = window.localStorage;
const USE_LOCAL_STORAGE = false;

export const onlyMyProjectsState = atom<boolean | null>({
    key: 'onlyMyProjects',
    default: null,
});

export const onlyMyProjectsFeatureEnabledState = atom<boolean>({
    key: 'enableOnlyMyProjects',
    default: false,
});
export const enableOnlyMyProjectsCheckboxState = atom<boolean>({
    key: 'onlyMyProjectsCheckbox',
    default: true,
});

type UseOnlyMyProjectsOptions = {
    pqiUser?: useOnlyMyProjects_pqiUser$key;
    team?: useOnlyMyProjects_team$key;
};

export const useOnlyMyProjectsCheckboxEnabled = () => {
    const [enableOnlyMyProjectsCheckbox] = useRecoilState(
        enableOnlyMyProjectsCheckboxState,
    );
    return enableOnlyMyProjectsCheckbox;
};

export const useOnlyMyProjectsFeatureEnabled = () => {
    const [onlyMyProjectsFeatureEnabled] = useRecoilState(
        onlyMyProjectsFeatureEnabledState,
    );
    return onlyMyProjectsFeatureEnabled;
};

export const useOnlyMyProjects = (
    options: UseOnlyMyProjectsOptions = {},
): [boolean, (enable: boolean) => void] => {
    const [onlyMyProjects, setOnlyMyProjects] =
        useRecoilState(onlyMyProjectsState);
    const [onlyMyProjectsFeatureEnabled, setOnlyMyProjectsFeatureEnabled] =
        useRecoilState(onlyMyProjectsFeatureEnabledState);
    const [, setEnableOnlyMyProjectsCheckbox] = useRecoilState(
        enableOnlyMyProjectsCheckboxState,
    );
    const pqiUser = useFragment(fragments.pqiUser, options.pqiUser);
    const team = useFragment(fragments.team, options.team);

    // Perhaps the initialization is a different hook. And not even a hook.
    // useInitOnlyMyProjectsState(...)
    useEffect(() => {
        // Temporary disable onlyMyProjects for all teams except a few
        // chosen teams in the production environment.
        if (
            team &&
            ![
                'stockholmshem',
                'binosight',
                'byggherren-ab',
                'byggforetaget-ab',
            ].includes(team.path) &&
            window.location.href.includes('app.binosight.com')
        ) {
            setOnlyMyProjectsFeatureEnabled(false);
            return;
        }
        if (!pqiUser || !team) {
            return;
        }
        const grants = pqiUser.grants.filter(
            ({ teamId }) => teamId === team.id,
        );
        const teamOrPortfolioGrants = grants.filter(
            (grant) => !grant.projectId,
        );

        // During dev, we can force true to test
        const FORCE_TRUE = false;

        const isAdminOnTeamOrPortfolio =
            FORCE_TRUE ||
            teamOrPortfolioGrants.some(
                (grant) =>
                    grant.role === 'TEAM_ADMIN' ||
                    grant.role === 'TEAM_OWNER' ||
                    grant.role === 'SURVEY_ADMIN',
            );
        const isResultsViewerOnTeamOrPortfolio =
            FORCE_TRUE ||
            teamOrPortfolioGrants.some(
                (grant) => grant.role === 'RESULTS_VIEWER',
            );
        // const isProjectCreatorOnTeamOrPortfolio = grants.some(
        //     (grant) => grant.role === 'PROJECT_CREATOR',
        // );
        const hasProjectGrant =
            FORCE_TRUE || grants.some((grant) => grant.projectId);
        const canToggle =
            isAdminOnTeamOrPortfolio || isResultsViewerOnTeamOrPortfolio;

        setOnlyMyProjectsFeatureEnabled(hasProjectGrant);
        setEnableOnlyMyProjectsCheckbox(canToggle);

        const localStorageOnlyMyProjects =
            localStorage?.getItem('onlyMyProjects');

        if (
            canToggle &&
            USE_LOCAL_STORAGE &&
            localStorageOnlyMyProjects !== null
        ) {
            setOnlyMyProjects(localStorageOnlyMyProjects === 'true');
        } else {
            setOnlyMyProjects(!isAdminOnTeamOrPortfolio);
        }
    }, [
        pqiUser,
        pqiUser?.grants,
        team,
        setOnlyMyProjects,
        setOnlyMyProjectsFeatureEnabled,
        setEnableOnlyMyProjectsCheckbox,
    ]);

    useEffect(() => {
        // todo We should save this in a pqi user object stored in the backend
        //      NOTE Perhaps (and I've though about this before) we should add
        //           a context and provider for the user information (and also
        //           this context would include information about the current
        //           team and project, such as subscription information etc)
        localStorage?.setItem(
            'onlyMyProjects',
            onlyMyProjects ? 'true' : 'false',
        );
    }, [onlyMyProjects]);

    return [
        onlyMyProjectsFeatureEnabled ? onlyMyProjects : false,
        setOnlyMyProjects,
    ];
};

const fragments = {
    pqiUser: graphql`
        fragment useOnlyMyProjects_pqiUser on PQiUser {
            grants {
                teamId
                portfolioId
                projectId
                role
            }
        }
    `,
    team: graphql`
        fragment useOnlyMyProjects_team on Team {
            id
            # So that we can enable the feature on some teams only
            path
        }
    `,
};
