import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';
import { generatePath, useMatch, useParams } from 'react-router';
import { Box, Chip } from '@mui/material';
import type { RouteParams } from '@/RootPaths';
import {
    getLastPortfolioPath,
    getPortfolioFullPathFromArray,
    teamPathParameterName,
} from '@/RootPaths';
import { usePortfolioList } from '@/features/team';
import {
    useOnlyMyProjects,
    useOnlyMyProjectsCheckboxEnabled,
    useOnlyMyProjectsFeatureEnabled,
} from '@/utils/useOnlyMyProjects.ts';
import type { BreadcrumbsProps } from '../Breadcrumbs';
import { Breadcrumbs } from '../Breadcrumbs';
import PortfolioBreadcrumbsDropdownMenu from './PortfolioBreadcrumbsDropdownMenu';
import type { PortfolioBreadcrumbs_team$key } from './__generated__/PortfolioBreadcrumbs_team.graphql';
import type { PortfolioBreadcrumbs_project$key } from './__generated__/PortfolioBreadcrumbs_project.graphql';
import type { PortfolioBreadcrumbs_pqiUser$key } from './__generated__/PortfolioBreadcrumbs_pqiUser.graphql';

export interface PortfolioBreadcrumbsProps {
    pqiUser: PortfolioBreadcrumbs_pqiUser$key;
    team: PortfolioBreadcrumbs_team$key;
    project: PortfolioBreadcrumbs_project$key;
}

/**
 * The PortfolioBreadcrumbs component is intended to be used when we have navigated to a TEAM, otherwise it will
 * return nothing.
 */
export function PortfolioBreadcrumbs({ ...props }: PortfolioBreadcrumbsProps) {
    const project = useFragment(fragments.project, props.project);
    const team = useFragment(fragments.team, props.team);
    const pqiUser = useFragment(fragments.pqiUser, props.pqiUser);
    const params = useParams<RouteParams>();
    const portfolioList = usePortfolioList(team, {
        pqiUser,
        includeMainPortfolio: true,
    });
    const match = useMatch(`/:${teamPathParameterName}/*`);

    const { formatMessage } = useIntl();
    const emptyIcon = useCallback((): ReactNode => null, []);
    const [onlyMyProjects] = useOnlyMyProjects();
    const onlyMyProjectsCheckboxEnabled = useOnlyMyProjectsCheckboxEnabled();
    const onlyMyProjectsFeatureEnabled = useOnlyMyProjectsFeatureEnabled();

    if (!team) {
        return null;
    }

    const portfolioPathFromUrl = getLastPortfolioPath(params);
    const portfolioListItem = project
        ? portfolioList.find((p) => p.portfolio.id === project.portfolio.id)
        : (portfolioList.find(
              (p) => p.portfolio.path === portfolioPathFromUrl,
          ) ?? portfolioList[0]);

    const portfolios = portfolioListItem?.getAncestorsAndSelf() ?? [];
    const portfolioPaths = portfolios.map((p) => p.path);

    const crumbs: Array<BreadcrumbsProps['crumbs'][0]> = portfolios.reduce(
        (acc, portfolio, index) => {
            return [
                ...acc,
                {
                    label: portfolio.name,
                    to: generatePath(match.pattern.path, {
                        [teamPathParameterName]: getPortfolioFullPathFromArray(
                            portfolioPaths.slice(0, index + 1),
                        ),
                        '*': project ? 'projects' : match.params['*'],
                    }),
                },
            ];
        },
        [],
    );

    if (project) {
        crumbs.push({
            label: project.name as ReactNode,
        });
    }

    if (!project && onlyMyProjects) {
        crumbs[crumbs.length - 1].label = (
            <Box display="flex" flexWrap="wrap">
                <Box sx={{ mr: 1 }}>{crumbs[crumbs.length - 1].label}</Box>
                <Chip
                    label={formatMessage({
                        defaultMessage: 'My projects',
                        description:
                            'Label to inform that we are viewing My Projects only',
                    })}
                    color="primary"
                    size="small"
                    sx={{ alignSelf: 'center', fontWeight: 'bold', mr: 0 }}
                />
            </Box>
        );
    }

    return (
        <Breadcrumbs
            renderIcon={emptyIcon}
            crumbs={crumbs}
            dropDownContent={
                (portfolioList.length > 1 ||
                    (onlyMyProjectsFeatureEnabled &&
                        onlyMyProjectsCheckboxEnabled)) &&
                !project ? (
                    <PortfolioBreadcrumbsDropdownMenu
                        portfolios={portfolioList}
                    />
                ) : null
            }
        />
    );
}

const fragments = {
    team: graphql`
        fragment PortfolioBreadcrumbs_team on Team {
            name
            path
            id
            ...usePortfolioList_team
        }
    `,
    project: graphql`
        fragment PortfolioBreadcrumbs_project on ProjectInterface {
            name
            portfolio {
                id
            }
        }
    `,
    pqiUser: graphql`
        fragment PortfolioBreadcrumbs_pqiUser on PQiUser {
            ...usePortfolioList_pqiUser
        }
    `,
};

export default PortfolioBreadcrumbs;
