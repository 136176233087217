/**
 * @generated SignedSource<<355a0850199f5bbdb57ebfc7026a9fdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTeamMemberForm_team$data = {
  readonly id: string;
  readonly portfolios: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly projects: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"RoleEditor_team" | "TeamMemberNotifications_team">;
  readonly " $fragmentType": "EditTeamMemberForm_team";
};
export type EditTeamMemberForm_team$key = {
  readonly " $data"?: EditTeamMemberForm_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTeamMemberForm_team">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTeamMemberForm_team",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TeamMemberNotifications_team"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RoleEditor_team"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "projects",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubPortfolio",
      "kind": "LinkedField",
      "name": "portfolios",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "a76d4f064376776bb6e2da6e04820776";

export default node;
